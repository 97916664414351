import { takeEvery, all, put, call } from "redux-saga/effects";

// actionTypes
import * as actionTypes from "../redux/actionTypes";

// actions
import {
  ProcessInterviewTableDataFetchSuccess,
  ProcessInterviewTableDataFetchFailed,
  ProcessInterviewTableDataPostSuccess,
  ProcessInterviewTableDataPutSuccess,
  ProcessExtInterviewTableDataFetchSuccess,
  ProcessExtInterviewTableDataFetchFailed,
  ProcessExtInterviewTableDataPostSuccess,
  ProcessExtInterviewTableDataPutSuccess,
  ProcessAllInterviewTableDataFetchSuccess,
  ProcessAllInterviewTableDataFetchFailed,
  LeavingOrgTableDataFetchSuccess,
  LeavingOrgTableDataFetchFailed,
  joiningProcessTableDataFetchSuccess,
  joiningProcessTableDataFetchFailed,
  nonjoiningProcessTableDataFetchSuccess,
  nonjoiningProcessTableDataFetchFailed,
  updateJoiningSuccess,
  ProcessInterviewTableDataPutFailed,
  ProcessInterviewTableDataPostFailed,
  createJoiningStart,
  createJoiningFailed,
  updateJoiningFailed,
  createJoiningSuccess,
} from "../redux/actions/processActions";

// services
import {
  fetchProcessInterview,
  addProcessInterview,
  editProcessInterview,
  fetchProcessExtInterview,
  addProcessExtInterview,
  editProcessExtInterview,
  fetchProcessAllInterview,
  fetchLeaningOrg,
  fetchProcessJoining,
  fetchProcessNonJoining,
  createJoining,
  updateJoining,
} from "../api/services/processService";

//NON JOINING Process Joining
function* loadProcessNonJoining(action) {
  try {
    const { data } = yield call(fetchProcessNonJoining, action.payload);
    yield put(nonjoiningProcessTableDataFetchSuccess({ ProcessNonjoining: data?.data?.result, count: data?.data?.totalCount }));
  } catch (error) {
    yield put(nonjoiningProcessTableDataFetchFailed(error.response));
  }
}

function* watchProcessNonJoining() {
  yield takeEvery(
    actionTypes.NON_JOINING_TABLE_DATA_FETCH_START,
    loadProcessNonJoining
  );
}

//Process Joining
function* loadProcessJoining(action) {
  try {
    const { data } = yield call(fetchProcessJoining, action.payload);
    yield put(joiningProcessTableDataFetchSuccess({ ProcessJoining: data?.data?.results, count: data?.data?.totalCount }));
  } catch (error) {
    yield put(joiningProcessTableDataFetchFailed(error.response));
  }
}
function* createProcessJoining(action) {
  try {
    const { data } = yield call(createJoining, action.payload);
    yield put(createJoiningSuccess(data));
  } catch (error) {
    yield put(createJoiningFailed(error.response));
  }
}

function* updateProcessJoining(action) {
  try {
    yield call(updateJoining, action.payload);
    yield put(updateJoiningSuccess(action.payload));
  } catch (error) {
    yield put(updateJoiningFailed(error.response));
  }
}

// Worker saga


function* loadProcessInterview(action) {
  try {
    const { data } = yield call(fetchProcessInterview, action.payload);
    yield put(ProcessInterviewTableDataFetchSuccess({ ProcessInterview: data?.data?.getAllInterview, count: data?.data?.totalCount }));
  } catch (error) {
    yield put(ProcessInterviewTableDataFetchFailed(error.response));
  }
}

function* createProcessInterview(action) {
  try {
    const { interview } = yield call(addProcessInterview, action.payload);
    yield put(ProcessInterviewTableDataPostSuccess(interview));
  } catch (error) {
    yield put(ProcessInterviewTableDataPostFailed(error.response));
  }
}

function* updateProcessInterview(action) {
  try {
    const { resources, ...other } = yield call(
      editProcessInterview,
      action.payload
    );
    yield call(updateProcessInterview, other);
    yield put(ProcessInterviewTableDataPutSuccess(action.payload));
  } catch (error) {
    yield put(ProcessInterviewTableDataPutFailed(error.response));
  }
}
// ***************************** PROCESS INT END *****************************

// ***************************** PROCESS EXT_INT START *****************************
function* loadProcessExtInterview(action) {
  try {
    const { data } = yield call(fetchProcessExtInterview, action.payload);
    yield put(
      ProcessExtInterviewTableDataFetchSuccess({ ProcessExtInterview: data?.data?.data, count: data?.data?.totalCount })
    );
  } catch (error) {
    yield put(ProcessExtInterviewTableDataFetchFailed());
  }
}

function* createProcessExtInterview(action) {
  try {
    const { data } = yield call(addProcessExtInterview, action.payload);
    // yield put(ProcessExtInterviewTableDataPostSuccess(data))
  } catch (error) {
    yield put(ProcessExtInterviewTableDataFetchFailed());
  }
}

function* updateProcessExtInterview(action) {
  try {
    yield call(editProcessExtInterview, action.payload);
    // yield call(updateProcessExtInterview, other);
    // yield put(ProcessExtInterviewTableDataPutSuccess(action.payload))
  } catch (error) {
    yield put(ProcessExtInterviewTableDataFetchFailed());
  }
}

// ***************************** PROCESS All_INT START *****************************
function* loadProcessAllInterview() {
  try {
    const { data } = yield call(fetchProcessAllInterview);
    yield put(
      ProcessAllInterviewTableDataFetchSuccess(data?.data?.externalinterviewData)
    );
  } catch (error) {
    yield put(ProcessAllInterviewTableDataFetchFailed());
  }
}

// ***************************** PROCESS All_INT END *****************************

///Leaving Organization
function* loadLeavingOrg(action) {
  try {
    const { data } = yield call(fetchLeaningOrg, action.payload);
    yield put(LeavingOrgTableDataFetchSuccess({ LeavingOragnization: data?.data.result, count: data?.data?.totalcount }));
  } catch (error) {
    yield put(LeavingOrgTableDataFetchFailed({ error: error.response.data.message }));
  }
}

// watcher saga
function* watchProcessInterview() {
  yield takeEvery(
    actionTypes.INTERVIEW_TABLE_DATA_FETCH_START,
    loadProcessInterview
  );
  yield takeEvery(
    actionTypes.INTERVIEW_DATA_POST_START,
    createProcessInterview
  );
  yield takeEvery(actionTypes.INTERVIEW_DATA_PUT_START, updateProcessInterview);
}

function* watchProcessExtInterview() {
  yield takeEvery(
    actionTypes.EXT_INTERVIEW_TABLE_DATA_FETCH_START,
    loadProcessExtInterview
  );
  yield takeEvery(
    actionTypes.EXT_INTERVIEW_DATA_POST_START,
    createProcessExtInterview
  );
  yield takeEvery(
    actionTypes.EXT_INTERVIEW_DATA_PUT_START,
    updateProcessExtInterview
  );
}

function* watchProcessAllInterview() {
  yield takeEvery(
    actionTypes.ALL_INTERVIEW_TABLE_DATA_FETCH_START,
    loadProcessAllInterview
  );
}
function* watchLeavingOrganization() {
  yield takeEvery(
    actionTypes.LEAVINGORG_TABLE_DATA_FETCH_START,
    loadLeavingOrg
  );
}

function* watchProcessJoining() {
  yield takeEvery(
    actionTypes.JOINING_TABLE_DATA_FETCH_START,
    loadProcessJoining
  );
  yield takeEvery(actionTypes.JOINING_CREATE_START, createProcessJoining);
  yield takeEvery(actionTypes.JOINING_UPDATE_START, updateProcessJoining);
}

export function* processSaga() {
  yield all([
    watchProcessInterview(),
    watchProcessExtInterview(),
    watchProcessAllInterview(),
    watchLeavingOrganization(),
    watchProcessJoining(),
    watchProcessNonJoining(),
  ]);
}
