import { combineReducers } from "redux";

import { accountMasterReducer } from "./accountMasterReducer";
import { clientAgreementReducer } from "./clientAgreementReducer";
import { clientMasterReducer } from "./clientMasterReducer";
import { externalProductMasterReducer } from "./externalProductMasterReducer";
import { externalResourceMasterReducer } from "./externalResourceMasterReducer";
import { projectTargetReducer } from "./projectTargetReducer";
import { purchaseOrderReducer } from "./purchaseOrderReducer";
import { requestClientReducer } from "./requestClientReducer";
import { resourceMasterReducer } from "./resourceMasterReducer";
import { archivedResourceReducer } from "./archivedResourceReducer";
import { settingsReducer } from "./settingsReducer";
import { technologyMasterReducer } from "./technologyMasterReducer";
import { userSettingsReducer } from "./userSettingsReducer";
import { vendorMasterReducer } from "./vendorMasterReducer";
import { inActiveResourceReducer } from "./inActiveResourceReducer";
import { reasonMasterReducer } from "./reasonMasterReducer";
import { currentResourceReducer } from "./currentResourceReducer";
import { currentPermissionsReducer } from "./currentPermissionsReducer";
import { roleSettingsReducer } from "./roleSettingsReducer"
import { archivedPurchaseReducer } from "./archivePurchaseReducer";

export const masterReducer = combineReducers({
  account: accountMasterReducer,
  clientAgreement: clientAgreementReducer,
  clientMaster: clientMasterReducer,
  externalProductMaster: externalProductMasterReducer,
  externalResourceMaster: externalResourceMasterReducer,
  projectTarget: projectTargetReducer,
  purchaseOrder: purchaseOrderReducer,
  requestClient: requestClientReducer,
  resourceMaster: resourceMasterReducer,
  archivedResource: archivedResourceReducer,
  inActiveResource: inActiveResourceReducer,
  settings: settingsReducer,
  technologyMaster: technologyMasterReducer,
  reasonMaster: reasonMasterReducer,
  userSettings: userSettingsReducer,
  vendorMaster: vendorMasterReducer,
  currentResource: currentResourceReducer,
  currentPermissions: currentPermissionsReducer,
  roleSettings:roleSettingsReducer,
  archivedPurchase:archivedPurchaseReducer
});
