import * as actionTypes from "../actionTypes";

// **************************** EXTERNAL REPORT START ****************************
export const externalReportTableDataFetchStart = () => {
  return {
    type: actionTypes.EXTERNAL_INTERVIEW_REPORT_START,
  };
};

export const externalReportTableDataFetchSuccess = (data) => {
  console.log(`databeta`, data);
  return {
    type: actionTypes.EXTERNAL_INTERVIEW_REPORT_SUCCESS,
    payload: data,
  };
};

export const externalReportTableDataFetchFailed = () => {
  return {
    type: actionTypes.INTERNAL_INTERVIEW_REPORT_FAILED,
  };
};
// **************************** EXTERNAL REPORT END ****************************

// **************************** INTERNAL REPORT START ****************************
export const internalReportTableDataFetchStart = (searchValue, from, to, head, order) => {
  console.log('searchValue :>> ', searchValue, from, to, head, order);
  return {
    type: actionTypes.INTERNAL_INTERVIEW_REPORT_START,
    payload:{searchValue, from, to, head, order}
  };
};

export const internalReportTableDataFetchSuccess = (data) => {
  console.log(`intetrnal resource data`, data);
  return {
    type: actionTypes.INTERNAL_INTERVIEW_REPORT_SUCCESS,
    payload: data,
  };
};

export const internalReportTableDataFetchFailed = () => {
  return {
    type: actionTypes.EXTERNAL_INTERVIEW_REPORT_FAILED,
  };
};
// **************************** INTERNAL REPORT END ****************************
