import * as actionTypes from "../actionTypes";
const inititalState = {
  isDataFetch: false,
  isError: false,
  invoiceStatus: [],
  invoiceHistory:[],
  externalInvoiceStatus:[],
  externalInvoiceHistory:[],
};

export const invoiceReducer = (state = inititalState, action) => {
  switch (action.type) {
    
    //Invoice Status Reducer
    case actionTypes.INVOICESTATUS_TABLE_DATA_FETCH_START:
      return {
        ...state,
        isDataFetch: true,
        isError: false,
      };
    case actionTypes.INVOICESTATUS_TABLE_DATA_FETCH_SUCCESS:
      return {
        ...state,
        isDataFetch: false,
        invoiceStatus: [...action.payload],
        isError: false,
      };
    case actionTypes.INVOICESTATUS_TABLE_DATA_FETCH_FAILED:
      return {
        ...state,
        isError: true,
        isDataFetch: false,
      };

       //Invoice History Reducer
    case actionTypes.INVOICEHISTORY_TABLE_DATA_FETCH_START:
      return {
        ...state,
        isDataFetch: true,
        isError: false,
      };
    case actionTypes.INVOICEHISTORY_TABLE_DATA_FETCH_SUCCESS:
      return {
        ...state,
        isDataFetch: false,
        invoiceHistory: [...action.payload],
        isError: false,
      };
    case actionTypes.INVOICEHISTORY_TABLE_DATA_FETCH_FAILED:
      return {
        ...state,
        isError: true,
        isDataFetch: false,
      };

      //External Invoice Status Reducer
    case actionTypes.EXTERNALINVOICE_TABLE_DATA_FETCH_START:
      return {
        ...state,
        isDataFetch: true,
        isError: false,
      };
    case actionTypes.EXTERNALINVOICE_TABLE_DATA_FETCH_SUCCESS:
      return {
        ...state,
        isDataFetch: false,
        externalInvoiceStatus: [...action.payload],
        isError: false,
      };
    case actionTypes.EXTERNALINVOICE_TABLE_DATA_FETCH_FAILED:
      return {
        ...state,
        isError: true,
        isDataFetch: false,
      };

      //External Invoice History Reducer
    case actionTypes.EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_START:
      return {
        ...state,
        isDataFetch: true,
        isError: false,
      };
    case actionTypes.EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_SUCCESS:
      return {
        ...state,
        isDataFetch: false,
        externalInvoiceHistory: [...action.payload],
        isError: false,
      };
    case actionTypes.EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_FAILED:
      return {
        ...state,
        isError: true,
        isDataFetch: false,
      };

      default: 
            return state
  }
};
