export const HOME = "/home";
export const CURRENT_RESOURCE = "/current-resource-filter";
export const INVOICE_MASTER_INVOICE_STATUS = "/invoice";
export const INVOICE_HISTORY = "/invoice/history"; 
export const INTERNAL_INVOICE_HISTORY = "/internal-invoice-history"; 
export const GET_ALL_PURCHASE_ARCHIVE="/purchase/getAllArchive";
export const UPDATE_ALL_PURCHASE_ARCHIVE = "/purchase-order/archive";
export const TEAM_MEMBER_= "/member";
export const REGESTER_FORM = "/client-requests";
export const ROLES = "roles";
export const ADD_ROLE = "roles";
export const EDIT_ROLE = `roles`;
export const PERMISSION = "roles"
export const INVOICE_FILTER = `invoice-master`