// **************************** LOGIN TYPES START ****************************
export const LOGIN_START = "LOGIN_START";
export const GOOGLE_LOGIN_START = "GOOGLE_LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILD = "LOGIN_FAILD";
export const LOGOUT = "LOGOUT";
export const SET_TOKEN = "SET_TOKEN"

// **************************** FORGET POSSWORD TYPES START ****************************

export const FORGET_START = "FORGET_START";
export const FORGET_SUCCESS = "FORGET_SUCCESS";
export const FORGET_FAILD = "FORGET_FAILD";

// **************************** LOGIN TYPES END ****************************

// **************************** CURRENT RESOURCE FILTER START****************************
export const CURRENT_RESOURCE_FETCH_START = "CURRENT_RESOURCE_FETCH_START";
export const CURRENT_RESOURCE_FETCH_SUCCESS = "CURRENT_RESOURCE_FETCH_SUCCESS";
export const CURRENT_RESOURCE_FETCH_FAILED = "CURRENT_RESOURCE_FETCH_FAILED";
// **************************** CURRENT RESOURCE FILTER END****************************

// **************************** UPCOMING RESOURCE FILTER START****************************
export const UPCOMING_RESOURCE_FETCH_START = "UPCOMING_RESOURCE_FETCH_START";
export const UPCOMING_RESOURCE_FETCH_SUCCESS =
  "UPCOMING_RESOURCE_FETCH_SUCCESS";
export const UPCOMING_RESOURCE_FETCH_FAILED = "UPCOMING_RESOURCE_FETCH_FAILED";
// **************************** UPCOMING RESOURCE FILTER END****************************

// **************************** PROJECT_TARGET FILTER START****************************
export const PROJECT_TARGET_FETCH_START = "PROJECT_TARGET_FETCH_START";
export const PROJECT_TARGET_FETCH_SUCCESS = "PROJECT_TARGET_FETCH_SUCCESS";
export const PROJECT_TARGET_FETCH_FAILED = "PROJECT_TARGET_FETCH_FAILED";
// **************************** PROJECT_TARGET FILTER END****************************

// **************************** COMPARE REPORT PROJECT TARGET START ****************************
export const CR_PROJECT_TARGET_DATA_FETCH_START =
  "CR_PROJECT_TARGET_DATA_FETCH_START";
export const CR_PROJECT_TARGET_DATA_FETCH_SUCCESS =
  "CR_PROJECT_TARGET_DATA_FETCH_SUCCESS";
export const CR_PROJECT_TARGET_DATA_FETCH_FAILED =
  "CR_PROJECT_TARGET_DATA_FETCH_FAILED";
// **************************** COMPARE REPORT PROJECT TARGET END ****************************

//***************************EXTERNAL REPORTS START************************/
export const EXTERNAL_INTERVIEW_REPORT_START =
  "EXTERNAL_INTERVIEW_REPORT_START";
export const EXTERNAL_INTERVIEW_REPORT_SUCCESS =
  "EXTERNAL_INTERVIEW_REPORT_SUCCESS";
export const EXTERNAL_INTERVIEW_REPORT_FAILED =
  "EXTERNAL_INTERVIEW_REPORT_FAILED";

//**********************************EXTERNAL REPORTS END************************ */

//***************************INTERNAL REPORTS START************************/
export const INTERNAL_INTERVIEW_REPORT_START =
  "INTERNAL_INTERVIEW_REPORT_START";
export const INTERNAL_INTERVIEW_REPORT_SUCCESS =
  "INTERNAL_INTERVIEW_REPORT_SUCCESS";
export const INTERNAL_INTERVIEW_REPORT_FAILED =
  "INTERNAL_INTERVIEW_REPORT_FAILED";

//**********************************INTERNAL REPORTS END************************ */

// **************************** PROJECT TARGET START ****************************
export const PROJECT_TABLE_DATA_FETCH_START = "PROJECT_TABLE_DATA_FETCH_START";
export const PROJECT_TABLE_DATA_FETCH_SUCCESS =
  "PROJECT_TABLE_DATA_FETCH_SUCCESS";
export const PROJECT_TABLE_DATA_FETCH_FAILED =
  "PROJECT_TABLE_DATA_FETCH_FAILED";

export const PROJECT_TARGET_CREATE_START = "PROJECT_TARGET_CREATE_START";
export const PROJECT_TARGET_CREATE_SUCCESS = "PROJECT_TARGET_CREATE_SUCCESS";
export const PROJECT_TARGET_CREATE_FAILED = "PROJECT_TARGET_CREATE_FAILED";

export const PROJECT_TARGET_UPDATE_START = "PROJECT_TARGET_UPDATE_START";
export const PROJECT_TARGET_UPDATE_SUCCESS = "PROJECT_TARGET_UPDATE_SUCCESS";
export const PROJECT_TARGET_UPDATE_FAILED = "PROJECT_TARGET_UPDATE_FAILED";
// **************************** PROJECT TARGET END ****************************

// **************************** VENDOR MASTER START ****************************
export const VENDOR_TABLE_DATA_FETCH_START = "VENDOR_TABLE_DATA_FETCH_START";
export const VENDOR_TABLE_DATA_FETCH_SUCCESS =
  "VENDOR_TABLE_DATA_FETCH_SUCCESS";
export const VENDOR_TABLE_DATA_FETCH_FAILED = "VENDOR_TABLE_DATA_FETCH_FAILED";

export const VENDOR_CREATE_START = "VENDOR_CREATE_START";
export const VENDOR_CREATE_SUCCESS = "VENDOR_CREATE_SUCCESS";
export const VENDOR_CREATE_FAILED = "VENDOR_CREATE_FAILED";

export const VENDOR_UPDATE_START = "VENDOR_UPDATE_START";
export const VENDOR_UPDATE_SUCCESS = "VENDOR_UPDATE_SUCCESS";
export const VENDOR_UPDATE_FAILED = "VENDOR_UPDATE_FAILED";

// **************************** VENDOR MASTER END ****************************

// **************************** RESOURCE MASTER START ****************************
export const RESOURCE_TABLE_DATA_FETCH_START =
  "RESOURCE_TABLE_DATA_FETCH_START";
export const RESOURCE_TABLE_DATA_FETCH_SUCCESS =
  "RESOURCE_TABLE_DATA_FETCH_SUCCESS";
export const RESOURCE_TABLE_DATA_FETCH_FAILED =
  "RESOURCE_TABLE_DATA_FETCH_FAILED";

export const RESOURCE_MASTER_CREATE_START = "RESOURCE_MASTER_CREATE_START";
export const RESOURCE_MASTER_CREATE_SUCCESS = "RESOURCE_MASTER_CREATE_SUCCESS";
export const RESOURCE_MASTER_CREATE_FAILED = "RESOURCE_MASTER_CREATE_FAILED";

export const RESOURCE_MASTER_UPDATE_START = "RESOURCE_MASTER_UPDATE_START";
export const RESOURCE_MASTER_UPDATE_SUCCESS = "RESOURCE_MASTER_UPDATE_SUCCESS";
export const RESOURCE_MASTER_UPDATE_FAILED = "RESOURCE_MASTER_UPDATE_FAILED";

// **************************** RESOURCE MASTER END ****************************

// **************************** ARCHIVED RESOURCE START ****************************
export const ARCHIVED_RESOURCE_TABLE_DATA_FETCH_START =
  "ARCHIVED_RESOURCE_TABLE_DATA_FETCH_START";
export const ARCHIVED_RESOURCE_TABLE_DATA_FETCH_SUCCESS =
  "ARCHIVED_RESOURCE_TABLE_DATA_FETCH_SUCCESS";
export const ARCHIVED_RESOURCE_TABLE_DATA_FETCH_FAILED =
  "ARCHIVED_RESOURCE_TABLE_DATA_FETCH_FAILED";
// **************************** ARCHIVED RESOURCE END ****************************


//**************************** INACTIVE RESOURCE START ****************************
export const INACTIVE_RESOURCE_TABLE_DATA_FETCH_START =
  "INACTIVE_RESOURCE_TABLE_DATA_FETCH_START";
export const INACTIVE_RESOURCE_TABLE_DATA_FETCH_SUCCESS =
  "INACTIVE_RESOURCE_TABLE_DATA_FETCH_SUCCESS";
export const INACTIVE_RESOURCE_TABLE_DATA_FETCH_FAILED =
  "INACTIVE_RESOURCE_TABLE_DATA_FETCH_FAILED";
// **************************** INACTIVE RESOURCE END ****************************

// **************************** USER SETTINGS START ****************************
export const USER_SETTINGS_TABLE_DATA_FETCH_START =
  "USER_SETTINGS_TABLE_DATA_FETCH_START";
export const USER_SETTINGS_TABLE_DATA_FETCH_SUCCESS =
  "USER_SETTINGS_TABLE_DATA_FETCH_SUCCESS";
export const USER_SETTINGS_TABLE_DATA_FETCH_FAILED =
  "USER_SETTINGS_TABLE_DATA_FETCH_FAILED";

export const USER_SETTINGS_TABLE_DATA_POST_START =
  "USER_SETTINGS_TABLE_DATA_POST_START";
export const USER_SETTINGS_TABLE_DATA_POST_SUCCESS =
  "USER_SETTINGS_TABLE_DATA_POST_SUCCESS";

export const USER_SETTINGS_TABLE_DATA_PUT_START =
  "USER_SETTINGS_TABLE_DATA_PUT_START";
export const USER_SETTINGS_TABLE_DATA_PUT_SUCCESS =
  "USER_SETTINGS_TABLE_DATA_PUT_SUCCESS";
// **************************** USER SETTINGS END ****************************

// **************************** CLIENT MASTER START ****************************
export const CLIENT_MASTER_TABLE_DATA_FETCH_START =
  "CLIENT_MASTER_TABLE_DATA_FETCH_START";
export const CLIENT_MASTER_TABLE_DATA_FETCH_SUCCESS =
  "CLIENT_MASTER_TABLE_DATA_FETCH_SUCCESS";
export const CLIENT_MASTER_TABLE_DATA_FETCH_FAILED =
  "CLIENT_MASTER_TABLE_DATA_FETCH_FAILED";

export const CLIENT_MASTER_CREATE_START = "CLIENT_MASTER_CREATE_START";
export const CLIENT_MASTER_CREATE_SUCCESS = "CLIENT_MASTER_CREATE_SUCCESS";

export const CLIENT_MASTER_UPDATE_START = "CLIENT_MASTER_UPDATE_START";
export const CLIENT_MASTER_UPDATE_SUCCESS = "CLIENT_MASTER_UPDATE_SUCCESS";
// **************************** CLIENT MASTER END ****************************

// **************************** CLIENT AGREEMENT START ****************************
export const CLIENT_AGREEMENT_TABLE_DATA_FETCH_START =
  "CLIENT_AGREEMENT_TABLE_DATA_FETCH_START";
export const CLIENT_AGREEMENT_TABLE_DATA_FETCH_SUCCESS =
  "CLIENT_AGREEMENT_TABLE_DATA_FETCH_SUCCESS";
export const CLIENT_AGREEMENT_TABLE_DATA_FETCH_FAILED =
  "CLIENT_AGREEMENT_TABLE_DATA_FETCH_FAILED";

export const CLIENT_AGREEMENT_CREATE_START = "CLIENT_AGREEMENT_CREATE_START";
export const CLIENT_AGREEMENT_CREATE_SUCCESS =
  "CLIENT_AGREEMENT_CREATE_SUCCESS";

export const CLIENT_AGREEMENT_UPDATE_START = "CLIENT_AGREEMENT_UPDATE_START";
export const CLIENT_AGREEMENT_UPDATE_SUCCESS =
  "CLIENT_AGREEMENT_UPDATE_SUCCESS";
// **************************** CLIENT AGREEMENT END ****************************

// **************************** EXTERNAL PRODUCT MASTER START ****************************
export const EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_START =
  "EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_START";
export const EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_SUCCESS =
  "EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_SUCCESS";
export const EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_FAILED =
  "EXTERNAL_PRODUCT_MASTER_TABLE_DATA_FETCH_FAILED";

export const EXTERNAL_PRODUCT_MASTER_CREATE_START =
  "EXTERNAL_PRODUCT_MASTER_CREATE_START";
export const EXTERNAL_PRODUCT_MASTER_CREATE_SUCCESS =
  "EXTERNAL_PRODUCT_MASTER_CREATE_SUCCESS";
export const EXTERNAL_PRODUCT_MASTER_CREATE_FAILED =
  "EXTERNAL_PRODUCT_MASTER_CREATE_SUCCESS";

export const EXTERNAL_PRODUCT_MASTER_UPDATE_START =
  "EXTERNAL_PRODUCT_MASTER_UPDATE_START";
export const EXTERNAL_PRODUCT_MASTER_UPDATE_SUCCESS =
  "EXTERNAL_PRODUCT_MASTER_UPDATE_SUCCESS";
export const EXTERNAL_PRODUCT_MASTER_UPDATE_FAILED =
  "EXTERNAL_PRODUCT_MASTER_UPDATE_SUCCESS";
// **************************** EXTERNAL PRODUCT MASTER END ****************************

// **************************** PURCHASE ORDER START ****************************
export const PURCHASE_ORDER_TABLE_DATA_FETCH_START =
  "PURCHASE_ORDER_TABLE_DATA_FETCH_START";
export const PURCHASE_ORDER_TABLE_DATA_FETCH_SUCCESS =
  "PURCHASE_ORDER_TABLE_DATA_FETCH_SUCCESS";
export const PURCHASE_ORDER_TABLE_DATA_FETCH_FAILED =
  "PURCHASE_ORDER_TABLE_DATA_FETCH_FAILED";

export const PURCHASE_ORDER_TABLE_DATA_POST_START =
  "PURCHASE_ORDER_TABLE_DATA_POST_START";
export const PURCHASE_ORDER_TABLE_DATA_POST_SUCCESS =
  "PURCHASE_ORDER_TABLE_DATA_POST_SUCCESS";

export const PURCHASE_ORDER_TABLE_DATA_PUT_START =
  "PURCHASE_ORDER_TABLE_DATA_PUT_START";
export const PURCHASE_ORDER_TABLE_DATA_PUT_SUCCESS =
  "PURCHASE_ORDER_TABLE_DATA_PUT_SUCCESS";
// **************************** PURCHASE ORDER END ****************************

// **************************** ARCHIVED PURCHASE START ****************************
export const ARCHIVED_PURCHASE_TABLE_DATA_FETCH_START =
  "ARCHIVED_PURCHASE_TABLE_DATA_FETCH_START";
export const ARCHIVED_PURCHASE_TABLE_DATA_FETCH_SUCCESS =
  "ARCHIVED_PURCHASE_TABLE_DATA_FETCH_SUCCESS";
export const ARCHIVED_PURCHASE_TABLE_DATA_FETCH_FAILED =
  "ARCHIVED_PURCHASE_TABLE_DATA_FETCH_FAILED";
// **************************** ARCHIVED PURCHASE END ****************************



// **************************** ACCOUNT MASTER START ****************************
export const ACCOUNT_TABLE_DATA_FETCH_START = "ACCOUNT_TABLE_DATA_FETCH_START";
export const ACCOUNT_TABLE_DATA_FETCH_SUCCESS =
  "ACCOUNT_TABLE_DATA_FETCH_SUCCESS";
export const ACCOUNT_TABLE_DATA_FETCH_FAILED =
  "ACCOUNT_TABLE_DATA_FETCH_FAILED";

export const ACCOUNT_CREATE_START = "ACCOUNT_CREATE_START";
export const ACCOUNT_CREATE_SUCCESS = "ACCOUNT_CREATE_SUCCESS";
export const ACCOUNT_CREATE_FAILED = "ACCOUNT_CREATE_FAILED";

export const ACCOUNT_UPDATE_START = "ACCOUNT_UPDATE_START";
export const ACCOUNT_UPDATE_SUCCESS = "ACCOUNT_UPDATE_SUCCESS";
export const ACCOUNT_UPDATE_FAILED = "ACCOUNT_UPDATE_FAILED";

// **************************** ACCOUNT MASTER END ****************************

// **************************** REQUEST CLIENT START ****************************
export const REQUEST_CLIENT_TABLE_DATA_FETCH_START =
  "REQUEST_CLIENT_TABLE_DATA_FETCH_START";
export const REQUEST_CLIENT_TABLE_DATA_FETCH_SUCCESS =
  "REQUEST_CLIENT_TABLE_DATA_FETCH_SUCCESS";
export const REQUEST_CLIENT_TABLE_DATA_FETCH_FAILED =
  "REQUEST_CLIENT_TABLE_DATA_FETCH_FAILED";

export const REQUEST_CLIENT_TABLE_DATA_UPDATE_START =
  "REQUEST_CLIENT_TABLE_DATA_UPDATE_START";
export const REQUEST_CLIENT_TABLE_DATA_UPDATE_SUCCESS =
  "REQUEST_CLIENT_TABLE_DATA_UPDATE_SUCCESS";
export const REQUEST_CLIENT_TABLE_DATA_UPDATE_FAILED =
  "REQUEST_CLIENT_TABLE_DATA_UPDATE_FAILED";
// **************************** REQUEST CLIENT END ****************************

// **************************** EXTERNAL RESOURCE MASTER START ****************************
export const EXTERNALRESOURCE_TABLE_DATA_FETCH_START =
  "EXTERNALRESOURCE_TABLE_DATA_FETCH_START";
export const EXTERNALRESOURCE_TABLE_DATA_FETCH_SUCCESS =
  "EXTERNALRESOURCE_TABLE_DATA_FETCH_SUCCESS";
export const EXTERNALRESOURCE_TABLE_DATA_FETCH_FAILED =
  "EXTERNALRESOURCE_TABLE_DATA_FETCH_FAILED";

export const EXTERNALRESOURCE_CREATE_START = "EXTERNALRESOURCE_CREATE_START";
export const EXTERNALRESOURCE_CREATE_SUCCESS =
  "EXTERNALRESOURCE_CREATE_SUCCESS";

export const EXTERNALRESOURCE_UPDATE_START = "EXTERNALRESOURCE_UPDATE_START";
export const EXTERNALRESOURCE_UPDATE_SUCCESS =
  "EXTERNALRESOURCE_UPDATE_SUCCESS";
// **************************** EXTERNAL RESOURCE MASTER START ****************************

// **************************** TECHNOLOGY MASTER START ****************************
export const TECHNOLOGY_TABLE_DATA_FETCH_START =
  "TECHNOLOGY_TABLE_DATA_FETCH_START";
export const TECHNOLOGY_TABLE_DATA_FETCH_SUCCESS =
  "TECHNOLOGY_TABLE_DATA_FETCH_SUCCESS";
export const TECHNOLOGY_TABLE_DATA_FETCH_FAILED =
  "TECHNOLOGY_TABLE_DATA_FETCH_FAILED";

export const TECHNOLOGY_DATA_POST_START = "TECHNOLOGY_DATA_POST_START";
export const TECHNOLOGY_DATA_POST_SUCCESS = "TECHNOLOGY_DATA_POST_SUCCESS";
export const TECHNOLOGY_DATA_POST_FAILED = "TECHNOLOGY_DATA_POST_FAILED";

export const TECHNOLOGY_DATA_PUT_START = "TECHNOLOGY_DATA_PUT_START";
export const TECHNOLOGY_DATA_PUT_SUCCESS = "TECHNOLOGY_DATA_PUT_SUCCESS";
export const TECHNOLOGY_DATA_PUT_FAILED = "TECHNOLOGY_DATA_PUT_FAILED";
// **************************** TECHNOLOGY MASTER END ****************************

// **************************** REASON MASTER START ****************************
export const REASON_MASTER_TABLE_DATA_FETCH_START =
  "REASON_MASTER_TABLE_DATA_FETCH_START";
export const REASON_MASTER_TABLE_DATA_FETCH_SUCCESS =
  "REASON_MASTER_TABLE_DATA_FETCH_SUCCESS";
export const REASON_MASTER_TABLE_DATA_FETCH_FAILED =
  "REASON_MASTER_TABLE_DATA_FETCH_FAILED";

export const REASON_MASTER_CREATE_START = "REASON_MASTER_CREATE_START";
export const REASON_MASTER_CREATE_SUCCESS = "REASON_MASTER_CREATE_SUCCESS";
export const REASON_MASTER_CREATE_FAILED = "REASON_MASTER_CREATE_SUCCESS";

export const REASON_MASTER_UPDATE_START = "REASON_MASTER_UPDATE_START";
export const REASON_MASTER_UPDATE_SUCCESS = "REASON_MASTER_UPDATE_SUCCESS";
export const REASON_MASTER_UPDATE_FAILED = "REASON_MASTER_UPDATE_SUCCESS";
// **************************** REASON MASTER END ****************************

// **************************** PROCESS INTERVIEW START ****************************
export const INTERVIEW_TABLE_DATA_FETCH_START =
  "INTERVIEW_TABLE_DATA_FETCH_START";
export const INTERVIEW_TABLE_DATA_FETCH_SUCCESS =
  "INTERVIEW_TABLE_DATA_FETCH_SUCCESS";
export const INTERVIEW_TABLE_DATA_FETCH_FAILED =
  "INTERVIEW_TABLE_DATA_FETCH_FAILED";

export const INTERVIEW_DATA_POST_START = "INTERVIEW_DATA_POST_START";
export const INTERVIEW_DATA_POST_SUCCESS = "INTERVIEW_DATA_POST_SUCCESS";
export const INTERVIEW_DATA_POST_FAILED = "INTERVIEW_DATA_POST_FAILED";

export const INTERVIEW_DATA_PUT_START = "INTERVIEW_DATA_PUT_START";
export const INTERVIEW_DATA_PUT_SUCCESS = "INTERVIEW_DATA_PUT_SUCCESS";
export const INTERVIEW_DATA_PUT_FAILED = "INTERVIEW_DATA_PUT_FAILED";
// ****************************  PROCESS INTERVIEW END ****************************

// **************************** PROCESS EXT-INTERVIEW START ****************************
export const EXT_INTERVIEW_TABLE_DATA_FETCH_START =
  "EXT_INTERVIEW_TABLE_DATA_FETCH_START";
export const EXT_INTERVIEW_TABLE_DATA_FETCH_SUCCESS =
  "EXT_INTERVIEW_TABLE_DATA_FETCH_SUCCESS";
export const EXT_INTERVIEW_TABLE_DATA_FETCH_FAILED =
  "EXT_INTERVIEW_TABLE_DATA_FETCH_FAILED";

export const EXT_INTERVIEW_DATA_POST_START = "INTERVIEW_DATA_POST_START";
export const EXT_INTERVIEW_DATA_POST_SUCCESS = "INTERVIEW_DATA_POST_SUCCESS";

export const EXT_INTERVIEW_DATA_PUT_START = "EXT_INTERVIEW_DATA_PUT_START";
export const EXT_INTERVIEW_DATA_PUT_SUCCESS = "EXT_INTERVIEW_DATA_PUT_SUCCESS";
// ****************************  PROCESS EXT-INTERVIEW END ****************************

// **************************** PROCESS ALL-INTERVIEW START ****************************
export const ALL_INTERVIEW_TABLE_DATA_FETCH_START =
  "ALL_INTERVIEW_TABLE_DATA_FETCH_START";
export const ALL_INTERVIEW_TABLE_DATA_FETCH_SUCCESS =
  "ALL_INTERVIEW_TABLE_DATA_FETCH_SUCCESS";
export const ALL_INTERVIEW_TABLE_DATA_FETCH_FAILED =
  "ALL_INTERVIEW_TABLE_DATA_FETCH_FAILED";

// ****************************  PROCESS ALL-INTERVIEW END ****************************

// **************************** JOINING PROCESS START ****************************
export const JOINING_TABLE_DATA_FETCH_START = "JOINING_TABLE_DATA_FETCH_START";
export const JOINING_TABLE_DATA_FETCH_SUCCESS =
  "JOINING_TABLE_DATA_FETCH_SUCCESS";
export const JOINING_TABLE_DATA_FETCH_FAILED =
  "JOINING_TABLE_DATA_FETCH_FAILED";

export const JOINING_CREATE_START = "JOINING_CREATE_START";
export const JOINING_CREATE_SUCCESS = "JOINING_CREATE_SUCCESS";
export const JOINING_CREATE_FAILED = "JOINING_CREATE_FAILED";

export const JOINING_UPDATE_START = "JOINING_UPDATE_START";
export const JOINING_UPDATE_SUCCESS = "JOINING_UPDATE_SUCCESS";
export const JOINING_UPDATE_FAILED = "JOINING_UPDATE_FAILED";
// **************************** JOINING PROCESS END ****************************

// **************************** NON JOINING PROCESS START ****************************
export const NON_JOINING_TABLE_DATA_FETCH_START =
  "NON_JOINING_TABLE_DATA_FETCH_START";
export const NON_JOINING_TABLE_DATA_FETCH_SUCCESS =
  "NON_JOINING_TABLE_DATA_FETCH_SUCCESS";
export const NON_JOINING_TABLE_DATA_FETCH_FAILED =
  "NON_JOINING_TABLE_DATA_FETCH_FAILED";

export const NON_JOINING_CREATE_START = "NON_JOINING_CREATE_START";
export const NON_JOINING_CREATE_SUCCESS = "NON_JOINING_CREATE_SUCCESS";
export const NON_JOINING_CREATE_FAILED = "NON_JOINING_CREATE_FAILED";

export const NON_JOINING_UPDATE_START = "NON_JOINING_UPDATE_START";
export const NON_JOINING_UPDATE_SUCCESS = "NON_JOINING_UPDATE_SUCCESS";
export const NON_JOINING_UPDATE_FAILED = "NON_JOINING_UPDATE_FAILED";

// **************************** NON JOINING PROCESS END ****************************

// **************************** LEAVING ORGANIZATION START ****************************
export const LEAVINGORG_TABLE_DATA_FETCH_START =
  "LEAVINGORG_TABLE_DATA_FETCH_START";
export const LEAVINGORG_TABLE_DATA_FETCH_SUCCESS =
  "LEAVINGORG_TABLE_DATA_FETCH_SUCCESS";
export const LEAVINGORG_TABLE_DATA_FETCH_FAILED =
  "LEAVINGORG_TABLE_DATA_FETCH_FAILED";

export const LEAVINGORG_CREATE_START = "LEAVINGORG_CREATE_START";
export const LEAVINGORG_CREATE_SUCCESS = "LEAVINGORG_CREATE_SUCCESS";
export const LEAVINGORG_CREATE_FAILED = "LEAVINGORG_CREATE_FAILED";

export const LEAVINGORG_UPDATE_START = "LEAVINGORG_UPDATE_START";
export const LEAVINGORG_UPDATE_SUCCESS = "LEAVINGORG_UPDATE_SUCCESS";
export const LEAVINGORG_UPDATE_FAILED = "LEAVINGORG_UPDATE_FAILED";
// ****************************  LEAVING ORGANIZATION END ****************************

// **************************** SETTING START ****************************
export const SETTING_TABLE_DATA_FETCH_START = "SETTING_TABLE_DATA_FETCH_START";
export const SETTING_TABLE_DATA_FETCH_SUCCESS =
  "SETTING_TABLE_DATA_FETCH_SUCCESS";
export const SETTING_TABLE_DATA_FETCH_FAILED =
  "SETTING_TABLE_DATA_FETCH_FAILED";



export const SETTING_TABLE_DATA_CREATE_START = "SETTING_TABLE_DATA_CREATE_START";
export const SETTING_TABLE_DATA_CREATE_SUCCESS = "SETTING_TABLE_DATA_CREATE_SUCCESS";
export const SETTING_TABLE_DATA_CREATE_FAILED = "SETTING_TABLE_DATA_CREATE_FAILED";

export const SETTING_TABLE_DATA_PUT_START = "SETTING_TABLE_DATA_PUT_START";
export const SETTING_TABLE_DATA_PUT_SUCCESS = "SETTING_TABLE_DATA_PUT_SUCCESS";
export const SETTING_TABLE_DATA_PUT_FAILED = "SETTING_TABLE_DATA_PUT_FAILED";

// **************************** SETTING START ****************************

// **************************** ROLES START ****************************
export const ROLE_TABLE_DATA_FETCH_START = "ROLES_TABLE_DATA_FETCH_START";
export const ROLE_TABLE_DATA_FETCH_SUCCESS = "ROLES_TABLE_DATA_FETCH_SUCCESS";
export const ROLE_TABLE_DATA_FETCH_FAILED = "ROLES_TABLE_DATA_FETCH_FAILED";

export const ROLE_TABLE_DATA_CREATE_START = "ROLE_TABLE_DATA_CREATE_START";
export const ROLE_TABLE_DATA_CREATE_SUCCESS = "ROLE_TABLE_DATA_CREATE_SUCCESS";
export const ROLE_TABLE_DATA_CREATE_FAILED = "ROLE_TABLE_DATA_CREATE_FAILED";

export const ROLE_TABLE_DATA_PUT_START = "ROLE_TABLE_DATA_PUT_START";
export const ROLE_TABLE_DATA_PUT_SUCCESS = "ROLE_TABLE_DATA_PUT_SUCCESS";
export const ROLE_TABLE_DATA_PUT_FAILED = "ROLE_TABLE_DATA_PUT_FAILED";
  

// **************************** INVOICE STATUS START ****************************
export const INVOICESTATUS_TABLE_DATA_FETCH_START =
  "INVOICESTATUS_TABLE_DATA_FETCH_START";
export const INVOICESTATUS_TABLE_DATA_FETCH_SUCCESS =
  "INVOICESTATUS_TABLE_DATA_FETCH_SUCCESS";
export const INVOICESTATUS_TABLE_DATA_FETCH_FAILED =
  "INVOICESTATUS_TABLE_DATA_FETCH_FAILED";
// **************************** INVOICE STATUS END *******************************

// **************************** INVOICE HISTORY START ****************************
export const INVOICEHISTORY_TABLE_DATA_FETCH_START =
  "INVOICEHISTORY_TABLE_DATA_FETCH_START";
export const INVOICEHISTORY_TABLE_DATA_FETCH_SUCCESS =
  "INVOICEHISTORY_TABLE_DATA_FETCH_SUCCESS";
export const INVOICEHISTORY_TABLE_DATA_FETCH_FAILED =
  "INVOICEHISTORY_TABLE_DATA_FETCH_FAILED";
// **************************** INVOICE HISTORY END *******************************

export const TDSSTATUS_TABLE_DATA_FETCH_FAILED =
  "TDSSTATUS_TABLE_DATA_FETCH_FAILED";
export const TDSSTATUS_TABLE_DATA_FETCH_SUCCESS =
  "TDSSTATUS_TABLE_DATA_FETCH_SUCCESS";
export const TDSSTATUS_TABLE_DATA_FETCH_START =
  "TDSSTATUS_TABLE_DATA_FETCH_START";

// **************************** EXTERNAL INVOICE STATUS START ****************************
export const EXTERNALINVOICE_TABLE_DATA_FETCH_START =
  "EXTERNALINVOICE_TABLE_DATA_FETCH_START";
export const EXTERNALINVOICE_TABLE_DATA_FETCH_SUCCESS =
  "EXTERNALINVOICE_TABLE_DATA_FETCH_SUCCESS";
export const EXTERNALINVOICE_TABLE_DATA_FETCH_FAILED =
  "EXTERNALINVOICE_TABLE_DATA_FETCH_FAILED";
// **************************** EXTERNAL INVOICE STATUS END *******************************

// **************************** EXTERNAL INVOICE HISTORY START ****************************
export const EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_START =
  "EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_START";
export const EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_SUCCESS =
  "EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_SUCCESS";
export const EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_FAILED =
  "EXTERNALINVOICEHISTORY_TABLE_DATA_FETCH_FAILED";
// **************************** EXTERNAL INVOICE HISTORY END *******************************

// **************************** PERMISSIONS START *******************************
export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS"
export const FETCH_PERMISSIONS_SUCCESS = "FETCH_PERMISSIONS_SUCCESS"
export const CLEAR_PERMISSIONS = "CLEAR_PERMISSIONS"
// **************************** PERMISSIONS END *********************************
