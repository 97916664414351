import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    archivedPurchase: [],
    isError: false,
    totalCount: 0,
    error: {}
}

export const archivedPurchaseReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.ARCHIVED_PURCHASE_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
            }


        case actionTypes.ARCHIVED_PURCHASE_TABLE_DATA_FETCH_SUCCESS:
            console.log('action.payload', action.payload)
            return {
                ...state,
                isDataFetch: false,
                archivedPurchase: [...action.payload.archivedPurchase],
                totalCount: action.payload.count,
            }

        case actionTypes.ARCHIVED_PURCHASE_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isDataFetch: false,
                isError: true,
                error: { ...action.payload }
            }
        default:
            return state
    }
}
