import * as actionTypes from "../../actionTypes";

const inititalState = {
  isDataFetch: false,
  currentResource: [],
  isError: false,
  error: {},
};

export const currentResourceReducer = (state = inititalState, action) => {
  switch (action.type) {
    case actionTypes.CURRENT_RESOURCE_FETCH_START:
      return {
        ...state,
        isDataFetch: true,
        isError: false,
        error: {},
      };

    case actionTypes.CURRENT_RESOURCE_FETCH_SUCCESS:
      return {
        ...state,
        isDataFetch: false,
        currentResource: [...action.payload.currentResource],
        isError: false,
        error: {},
      };

    case actionTypes.CURRENT_RESOURCE_FETCH_FAILED:
      return {
        ...state,
        isError: true,
        isDataFetch: false,
        currentResource: [],
        error: { ...action.payload },
      };

    default:
      return state;
  }
};
