import { privateRequest } from "../axiosConfig/privateRequest";
import { HOME, CURRENT_RESOURCE, PERMISSION } from "../apiEndpoints";
import { getApiParams } from "../../utility/getApiParams";

export const fetchCurrentResource = (val) => {
  let url =
    HOME +
    CURRENT_RESOURCE +
    `?location=${val.location}&Technology=${val.technology}&exp=${val.exp}`;
  return privateRequest.get(url);
};

// **************************** COMPARE REPORT PROJECT TARGET START ****************************
export const fetchCRProjectTarget = (pagination) => {
  return privateRequest
    .get(
      `/report/projectTargets?search=${pagination.searchValue}&pageNo=${pagination.pageNo}&perPage=${pagination.perPage}&sort=${pagination.head}&orderby=${pagination.order}`
    )
    .then((res) => res.data.data);
};
// **************************** COMPARE REPORT PROJECT TARGET END ****************************

// ***************************** PROJECT TARGET MASTER START *****************************
export const fetchProjectTarget = (pagination, searchValue = "") => {
  return privateRequest.get(
    `/project-target?search=${pagination.searchValue}&pageNo=${pagination.pageNo}&perPage=${pagination.perPage}&sort=${pagination.head}&orderby=${pagination.order}`
  );
};
// **************************** PROJECT TARGET MASTER END ****************************

export const fetchVendorMaster = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest
    .get(
      `/vendors${params}`
    )
    .then((res) => res.data.data);
};

export const fetchResourceMaster = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest
    .get(`/resource${params}`)
    .then((res) => res.data.data)
};

export const fetchArchivedResource = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest.get(
    `/resource/archive/all${params}`
  ).then((res) => res?.data?.data)
};

export const fetchInActiveResource = (pagination, search = "") => {
  if (pagination.order == "" && pagination.head == "") {
    return privateRequest.get(
      `/resource/getAllInactive?search=${pagination.searchValue}&pageNo=${pagination.pageNo}&perPage=${pagination.perPage}`
    ); // .then(res => res.data.data)
  } else {
    return privateRequest.get(
      `/resource/getAllInactive?search=${pagination.searchValue}&pageNo=${pagination.pageNo}&perPage=${pagination.perPage}&sort=${pagination.head}&orderby=${pagination.order}`
    );
  }
};

export const fetchUserSettingsMaster = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest
    .get(`/users${params}`)
    .then((res) => res.data.data);
};

export const fetchArchiveMaster = () => {
  return privateRequest.get(`getAllArchive`);
};

export const fetchClientMaster = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest
    .get(
      `/clients${params}`
    )
    .then((res) => res.data.data);
};

export const fetchClientAgreement = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest
    .get(
      `/client-agreements${params}`
    )
    .then((res) => res.data.data);
};

export const fetchExternalProductMaster = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest
    .get(
      `/external-products${params}`
    )
    .then((res) => res.data.data);
};

export const fetchPurchaseOrder = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest
    .get(
      `/purchase-order${params}`
    )
    .then((res) => res.data.data);
};

export const fetchArchivedPurchase = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest.get(
    `/purchase-order/getArchive${params}`
  ).then((res) => res?.data?.data);
};

export const addPurchaseOrder = (data) => {
  return privateRequest.post("/purchase-order", data).then((res) => res);
};

export const editPurchaseOrder = (data) => {
  return privateRequest.put(`/purchase-order/${data.id}`, data).then((res) => res);
};

export const fetchAccount = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest
    .get(
      `/account-teams${params}`
    )
    .then((res) => res.data.data);
};

export const fetchRequestClient = (pagination) => {
  const params = getApiParams(pagination)

  return privateRequest
    .get(
      `/client-requests${params}`
    )
    .then((res) => res.data.data);
};

//setting
export const fetchSetting = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest.get(`/app-settings${params}`).then((res) => res.data.data);
};

export const addSetting = (data) => {
  return privateRequest.post(`/app-settings`, data).then((res) => res);
};

export const updateSetting = (data) => {
  return privateRequest.put(`/app-settings/${data.get('id')}`, data).then((res) => res);
};

// role

export const fetchRoleSetting = (pagination) => {
  const params = getApiParams(pagination)

  return privateRequest
    .get(
      `/roles${params}`
    )
    .then((res) => res.data.data);
};


export const addRoleSetting = (data) => {
  return privateRequest
    .post("/roles", data)
    .then((res) => res?.data?.data);
};

export const editRoleSetting = (data) => {
  return privateRequest.put(`/roles/${data.id}`, data).then((res) => res);
};


// fetch

export const fetchexternalResourceMaster = (pagination) => {
  const params = getApiParams(pagination)

  return privateRequest.get(`/external-resource${params}`).then((res) => res.data.data);
};

export const fetchTechnologyMaster = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest
    .get(
      `/technologies${params}`
    )
    .then((res) => res.data.data);
};
export const fetchReasonMaster = (pagination) => {
  const params = getApiParams(pagination)

  return privateRequest
    .get(
      `/reason${params}`
    )
    .then((res) => res.data.data);
};


// Post (Create)
export const createAccount = (data) => {
  return privateRequest.post("/account-teams", data).then((res) => res);
  // return {id: Date.now(), ...data}
};
export const createVendor = (data) => {
  return privateRequest.post("/vendors", data).then((res) => res?.data?.data);
};
export const createClientAgreement = (data) => {
  return privateRequest
    .post("/client-agreements", data)
    .then((res) => res?.data?.data);
};
export const createExProductMaster = (data) => {
  return privateRequest
    .post("/external-products", data)
    .then((res) => res?.data?.data);
};
export const addTechnologyMaster = (data) => {
  return privateRequest
    .post("/technologies", data)
    .then((res) => res?.data?.data);
};
export const addReasonMaster = (data) => {
  return privateRequest.post("/reason", data).then((res) => res?.data?.data);
};
export const createResource = (data) => {
  return privateRequest.post("/resource", data).then((res) => res?.data?.data);
};
export const createProjectTarget = (data) => {
  return privateRequest
    .post("/project-target", data)
    .then((res) => res?.data?.data);
};
export const createClient = (data) => {
  return privateRequest.post("/clients", data).then((res) => res?.data?.data);
};
export const addUserSetting = (data) => {
  return privateRequest.post("/users", data).then((res) => res);
};
export const createExternalResource = (data) => {
  return privateRequest
    .post("/external-resource", data)
    .then((res) => res?.data?.data);
};





// Put (update)
export const updateAcount = (data) => {
  return privateRequest.put(`/account-teams/${data.id}`, data).then((res) => res);
  // return data
};

export const updateVendor = (data) => {
  return privateRequest.put(`/vendors/${data.id}`, data).then((res) => res);
};
export const updateClientAgreement = (data) => {
  return privateRequest
    .put(`/client-agreements/${data.id}`, data)
    .then((res) => res);
};
export const updateExProductMaster = (data) => {
  const { id, created_at, updated_at, ...other } = data;
  return privateRequest
    .put(`/external-products/${id}`, other)
    .then((res) => res);
};
export const editTechnologyMaster = (data) => {
  const { id, ...payload } = data;
  return privateRequest.put(`/technologies/${id}`, payload).then((res) => res);
};
export const editReasonMaster = (data) => {
  const { id, created_at, updated_at, ...other } = data;
  return privateRequest.put(`/reason/${id}`, other).then((res) => res);
};
export const updateResource = (data) => {
  return privateRequest.put(`/resource/${data.id}`, data).then((res) => res);
};
export const updateProjectTarget = (data) => {
  return privateRequest
    .put(`/project-target/${data.id}`, data)

    .then((res) => res);
};
export const updateClient = (data) => {
  return privateRequest.put(`/clients/${data.id}`, data).then((res) => res);
};
export const editUserSetting = (data) => {
  return privateRequest.put(`/users/${data.id}`, data).then((res) => res);
};
export const updateExternalResource = (data) => {
  return privateRequest
    .put(`/external-resource/${data.id}`, data)
    .then((res) => res);
};

export const updateRequestClient = (data) => {
  return privateRequest
    .put(`/client-requests/${data.id}/accept`, data)
    .then((res) => res.data.data);
};

export const fetchPermissions = (id) => {
  return privateRequest.get(`${PERMISSION}/${id}`).then(res => res.data.data)
}
