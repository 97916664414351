import { privateRequest } from "../axiosConfig/privateRequest";

export const fetchExternalReport = () => {
  return privateRequest
    .get("/external-interview-report")
    .then((res) => res.data);
};

export const fetchInternalReport = (pagination) => {
    return privateRequest.get(`/interviews?search=${pagination.searchValue}&from=${pagination.from}&to=${pagination.to}&sort=${pagination.head}&orderby=${pagination.order}`).then((res) => res.data);
};
