import { takeEvery, all, put, call } from "redux-saga/effects";

// actionTypes
import * as actionTypes from "../redux/actionTypes";

// actions
import {
  loginActionFaild,
  loginActionSuccess,
} from "../redux/actions/loginActions";
import { forgetActionSuccess, forgetActionFaild } from "../redux/actions/forgetPasswordAction";


// services
import { loginGoogleUser, loginUser, forgotPassword } from "../api/services/loginService";

function* loadForgetPassword(action) {
  try {
    const { user } = yield call(forgotPassword, action.payload);
    yield put(forgetActionSuccess({ user }));
  }
  catch (error) {
    
    yield put(forgetActionFaild(error.response));
  }
}




// Worker saga
function* loadUser(action) {
  try {
    const { accessToken:token, permissions, ...others } = yield call(loginUser, action.payload);
    yield localStorage.setItem(
      "authUser",
      JSON.stringify({...others, permissions, token, userName:action.payload.email })
    );
    yield put(loginActionSuccess({ ...others, permissions, token, userName: action.payload.email }));
  } catch (error) {
    yield put(loginActionFaild(error.response));
  }
}

function* loadGoogleUser(action) {
  try {
    const { token, userData } = yield call(loginGoogleUser, action.payload);
    yield localStorage.setItem(
      "authUser",
      JSON.stringify({ ...userData, token })
    );
    yield put(loginActionSuccess({ ...userData, token }));
  } catch (error) {
    yield put(loginActionFaild(error.response));
  }
}
// watcher saga
function* watchLogin() {
  yield takeEvery(actionTypes.LOGIN_START, loadUser);
  yield takeEvery(actionTypes.GOOGLE_LOGIN_START, loadGoogleUser);
  yield takeEvery(actionTypes.FORGET_START, loadForgetPassword)
}

export function* loginSaga() {
  yield all([watchLogin()]);
}
