import * as actionTypes from "../actionTypes";
import { showSuccessNotification } from "../../utility";

const configToast = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

// **************************** PROCESS-INTERVIEW  START ****************************
export const ProcessInterviewTableDataFetchStart = (
  search,
  pageNo,
  perPage,
  startDate,
  endDate
) => {
  return {
    type: actionTypes.INTERVIEW_TABLE_DATA_FETCH_START,
    payload: { search, pageNo, perPage, fromDate: startDate, toDate: endDate },
  };
};

export const ProcessInterviewTableDataFetchSuccess = (data) => {
  return {
    type: actionTypes.INTERVIEW_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const ProcessInterviewTableDataFetchFailed = (error) => {
  return {
    type: actionTypes.INTERVIEW_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

export const ProcessInterviewTableDataPostStart = (data) => {
  return {
    type: actionTypes.INTERVIEW_DATA_POST_START,
    payload: data,
  };
};

export const ProcessInterviewTableDataPostSuccess = (data) => {
  showSuccessNotification("Data Added Successfully", configToast);
  return {
    type: actionTypes.INTERVIEW_DATA_POST_SUCCESS,
    payload: data,
  };
};

export const ProcessInterviewTableDataPostFailed = (error) => {
  return {
    type: actionTypes.INTERVIEW_DATA_POST_FAILED,
    payload: error,
  };
};

export const ProcessInterviewTableDataPutStart = (data) => {
  return {
    type: actionTypes.INTERVIEW_DATA_PUT_START,
    payload: data,
  };
};

export const ProcessInterviewTableDataPutSuccess = (data) => {
  showSuccessNotification("Data Updated Successfully", configToast);
  return {
    type: actionTypes.INTERVIEW_DATA_PUT_SUCCESS,
    payload: data,
  };
};

export const ProcessInterviewTableDataPutFailed = (error) => {
  return {
    type: actionTypes.INTERVIEW_DATA_PUT_FAILED,
    payload: error,
  };
};
// ****************************  PROCESS INTERVIEW END ****************************

// **************************** PROCESS EXT-INTERVIEW  START ****************************
export const ProcessExtInterviewTableDataFetchStart = (
  search,
  pageNo,
  perPage
) => {
  return {
    type: actionTypes.EXT_INTERVIEW_TABLE_DATA_FETCH_START,
    payload: {
      search,
      pageNo,
      perPage
    }
  };
};

export const ProcessExtInterviewTableDataFetchSuccess = (data) => {
  return {
    type: actionTypes.EXT_INTERVIEW_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const ProcessExtInterviewTableDataFetchFailed = (error) => {
  return {
    type: actionTypes.EXT_INTERVIEW_TABLE_DATA_FETCH_FAILED,
  };
};

export const ProcessExtInterviewTableDataPostStart = (data) => {
  return {
    type: actionTypes.EXT_INTERVIEW_DATA_POST_START,
    payload: data,
  };
};

export const ProcessExtInterviewTableDataPostSuccess = (data) => {
  return {
    type: actionTypes.EXT_INTERVIEW_DATA_POST_SUCCESS,
    payload: data,
  };
};

export const ProcessExtInterviewTableDataPutStart = (data) => {
  return {
    type: actionTypes.EXT_INTERVIEW_DATA_PUT_START,
    payload: data,
  };
};

export const ProcessExtInterviewTableDataPutSuccess = (data) => {
  return {
    type: actionTypes.EXT_INTERVIEW_DATA_PUT_SUCCESS,
    payload: data,
  };
};

// ****************************  PROCESS EXT-INTERVIEW END ****************************

// **************************** PROCESS ALL-INTERVIEW  START ****************************
export const ProcessAllInterviewTableDataFetchStart = () => {
  return {
    type: actionTypes.ALL_INTERVIEW_TABLE_DATA_FETCH_START,
  };
};

export const ProcessAllInterviewTableDataFetchSuccess = (data) => {
  showSuccessNotification("Data Loaded Succesfully!", configToast);
  return {
    type: actionTypes.ALL_INTERVIEW_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const ProcessAllInterviewTableDataFetchFailed = (error) => {
  return {
    type: actionTypes.ALL_INTERVIEW_TABLE_DATA_FETCH_FAILED,
    // payload: error,
  };
};

// ****************************  PROCESS ALL-INTERVIEW END ****************************
// **************************** LEAVING ORGANIZATION START ****************************
export const LeavingOrgTableDataFetchStart = (
  search,
  pageNo,
  perPage,
) => {
  return {
    type: actionTypes.LEAVINGORG_TABLE_DATA_FETCH_START,
    payload: { search, pageNo, perPage },
  };
};

export const LeavingOrgTableDataFetchSuccess = (data) => {
  return {
    type: actionTypes.LEAVINGORG_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const LeavingOrgTableDataFetchFailed = (error) => {
  return {
    type: actionTypes.LEAVINGORG_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};
// ADD LEAVING ORG
export const createLeavingOrgStart = (data) => {
  return {
    type: actionTypes.LEAVINGORG_CREATE_START,
    payload: data,
  };
};
export const createLeavingOrgSuccess = (data) => {
  showSuccessNotification("Data Added Successfully!", configToast);
  return {
    type: actionTypes.LEAVINGORG_CREATE_SUCCESS,
    payload: data,
  };
};
export const createLeavingOrgFailed = (error) => {
  return {
    type: actionTypes.LEAVINGORG_CREATE_FAILED,
    payload: error,
  };
};
// UPDATE LEAVING ORG
export const updateLeavingOrgStart = (data) => {
  return {
    type: actionTypes.LEAVINGORG_UPDATE_START,
    payload: data,
  };
};
export const updateLeavingOrgSuccess = (data) => {
  showSuccessNotification("Data Updated Successfully!", configToast);
  return {
    type: actionTypes.LEAVINGORG_UPDATE_SUCCESS,
    payload: data,
  };
};
export const updateLeavingOrgFailed = (error) => {
  return {
    type: actionTypes.LEAVINGORG_UPDATE_FAILED,
    payload: error,
  };
};

// ****************************  LEAVING ORGANIZATION END ****************************

// **************************** JOINING PROCESS START ****************************
export const joiningProcessTableDataFetchStart = (
  search,
  pageNo,
  perPage,
) => {
  return {
    type: actionTypes.JOINING_TABLE_DATA_FETCH_START,
    payload: { search, pageNo, perPage },
  };
};

export const joiningProcessTableDataFetchSuccess = (data) => {
  return {
    type: actionTypes.JOINING_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const joiningProcessTableDataFetchFailed = (error) => {
  return {
    type: actionTypes.JOINING_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// ADD JOINING
export const createJoiningStart = (data) => {
  return {
    type: actionTypes.JOINING_CREATE_START,
    payload: data,
  };
};

export const createJoiningSuccess = (data) => {
  showSuccessNotification("Data Added Successfully!", configToast);
  return {
    type: actionTypes.JOINING_CREATE_SUCCESS,
    payload: data,
  };
};

export const createJoiningFailed = (error) => {
  return {
    type: actionTypes.JOINING_CREATE_FAILED,
    payload: error,
  };
};

// UPDATE JOINING
export const updateJoiningStart = (data) => {
  return {
    type: actionTypes.JOINING_UPDATE_START,
    payload: data,
  };
};

export const updateJoiningSuccess = (data) => {
  showSuccessNotification("Data Updated Successfully!", configToast);
  return {
    type: actionTypes.JOINING_UPDATE_SUCCESS,
    payload: data,
  };
};

export const updateJoiningFailed = (error) => {
  return {
    type: actionTypes.JOINING_UPDATE_FAILED,
    payload: error,
  };
};
// **************************** JOINING PROCESS END ****************************

// **************************** NON JOINING PROCESS START ****************************
export const nonjoiningProcessTableDataFetchStart = (
  search,
  pageNo,
  perPage,
  startDate,
  endDate
) => {
  return {
    type: actionTypes.NON_JOINING_TABLE_DATA_FETCH_START,
    payload: { search, pageNo, perPage, fromDate: startDate, toDate: endDate },
  };
};

export const nonjoiningProcessTableDataFetchSuccess = (data) => {
  return {
    type: actionTypes.NON_JOINING_TABLE_DATA_FETCH_SUCCESS,
    payload: data,
  };
};

export const nonjoiningProcessTableDataFetchFailed = (error) => {
  return {
    type: actionTypes.NON_JOINING_TABLE_DATA_FETCH_FAILED,
    payload: error,
  };
};

// ADD NON JOINING
export const createnonJoiningStart = (data) => {
  return {
    type: actionTypes.NON_JOINING_CREATE_START,
    payload: data,
  };
};
export const createnonJoiningSuccess = (data) => {
  showSuccessNotification("Data Added Successfully!", configToast);
  return {
    type: actionTypes.NON_JOINING_CREATE_SUCCESS,
    payload: data,
  };
};
export const createnonJoiningFailed = (error) => {
  return {
    type: actionTypes.NON_JOINING_CREATE_FAILED,
    payload: error,
  };
};

// UPDATE NON JOINING
export const updatenonJoiningStart = (data) => {
  return {
    type: actionTypes.NON_JOINING_UPDATE_START,
    payload: data,
  };
};
export const updatenonjoiningSuccess = (data) => {
  showSuccessNotification("Data Updated Successfully!12", configToast);
  return {
    type: actionTypes.NON_JOINING_UPDATE_SUCCESS,
    payload: data,
  };
};
export const updatenonJoiningFailed = (error) => {
  return {
    type: actionTypes.NON_JOINING_UPDATE_FAILED,
    payload: error,
  };
};
// **************************** NON JOINING PROCESS END ****************************