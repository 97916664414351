import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    roleSettings: [],
    totalCount: 0,
    isError: false,
    isAdding: false,
    isUpdating: false,
    error: {}
}

export const roleSettingsReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.ROLE_TABLE_DATA_FETCH_START:

            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.ROLE_TABLE_DATA_CREATE_START:
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }

        case actionTypes.ROLE_TABLE_DATA_PUT_START:
            return {
                ...state,
                isUpdating: true,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.ROLE_TABLE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                isDataFetch: false,
                roleSettings: [...action.payload.roles],
                totalCount: action.payload.count,
                isError: false,
                error: {}
            }

        case actionTypes.ROLE_TABLE_DATA_CREATE_SUCCESS:
            const newRole = [action.payload.roles, ...state.roleSettings]
            return {
                ...state,
                roleSettings: [...newRole],
                totalCount: action.payload.count,
                isAdding: false,
                error: {},
            }

        case actionTypes.ROLE_TABLE_DATA_PUT_SUCCESS:
            const updateRole = [...state.roleSettings]
            const findIndexU = updateRole.findIndex(d => d.id === action.payload.id)
            updateRole[findIndexU] = { ...action.payload }
            return {
                ...state,
                roleSettings: [...updateRole],
                isUpdating: false,
                isDataFetch: false,
                error: {}
            }

        case actionTypes.ROLE_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                roleSettings: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }
        case actionTypes.ROLE_TABLE_DATA_CREATE_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                roleSettings: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }

        case actionTypes.ROLE_TABLE_DATA_PUT_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                roleSettings: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }
        default:
            return state
    }
}
