import React from 'react';
import './Spinner.css';

const Spinner = () => {
  return (
    <div className='main-loader'>
      <div className='loader'></div>
    </div>
  )
}

export default Spinner