export const getApiParams = (params) => {
    if (!params) {
        return ""
    }

    const key = Object.keys(params)
    let url = []
    key.forEach((k) => {
        if (params[k] !== "" && params[k] !== undefined && params[k] !== null) {
            if (k === "search") {
                url.push(`${k}=${params[k]?.trim(" ")}`);  // fixed trim() usage
            } else {
                url.push(`${k}=${params[k]}`);
            }
        }
    })
    url = url.join('&')
    url = url ? `?${url}` : ''
    return url
}