import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    accountMaster: [],
    isError: false,
    isAdding: false,
    isUpdating: false,
    totalCount: 0,
    error: {}
}

export const accountMasterReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.ACCOUNT_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.ACCOUNT_CREATE_START:
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }

        case actionTypes.ACCOUNT_UPDATE_START:
            return {
                ...state,
                isUpdating: true,
                isError: false,
                error: {}
            }

        case actionTypes.ACCOUNT_TABLE_DATA_FETCH_SUCCESS:
           
           
            return {
                ...state,
                isDataFetch: false,
                accountMaster: [...action.payload.data],
                totalCount: action.payload.count,
                isError: false,
                error: {}
            }

        case actionTypes.ACCOUNT_CREATE_SUCCESS:
            return {
                ...state,
                accountMaster: [...action.payload.data],
                // totalCount: action.payload.count,
                isAdding: false,
                error: {},
            }

        case actionTypes.ACCOUNT_UPDATE_SUCCESS:
            return {
                ...state,
                accountMaster: [...action.payload.data],
                isUpdating: false,
                error: {}
            }

        case actionTypes.ACCOUNT_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                accountMaster: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }

        case actionTypes.ACCOUNT_CREATE_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                accountMaster: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }
        case actionTypes.ACCOUNT_UPDATE_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                accountMaster: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }
        default:
            return state
    }
}
