import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    Setting: [],
    isError: false,
    isUpdating: false,
    isAdding:false,
    error: {},
    totalCount:0
}

export const settingsReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.SETTING_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                isAdding:false,
                isUpdating: false,
                error: {}
            }

        case actionTypes.SETTING_TABLE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                isDataFetch: false,
                Setting: [...action.payload.Setting],
                totalCount: action.payload.count,
                isError: false,
                isAdding:false,
                isUpdating: false,
                error: {}
            }

        case actionTypes.SETTING_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                Setting: [],
                error: { ...action.payload }
            }

        case actionTypes.SETTING_TABLE_DATA_CREATE_START:
            return {
                ...state,
                isAdding: true,
                isDataFetch: true
            }
        case actionTypes.SETTING_TABLE_DATA_CREATE_SUCCESS:
            var settingData = [...state.Setting]
            return {
                ...state,
                Setting: [...settingData],
                isError: false,
                isAdding:false,
                isUpdating: false,
                isDataFetch: false,
                error: {}
            }
        case actionTypes.SETTING_TABLE_DATA_PUT_START:
            return {
                ...state,
                isUpdating: true,
                isDataFetch: true,
            }
        case actionTypes.SETTING_TABLE_DATA_PUT_SUCCESS:
            var settingData = [...state.Setting]
            return {
                ...state,
                Setting: [...settingData],
                isError: false,
                isAdding:false,
                isUpdating: false,
                isDataFetch: false,
                error: {}
            }
        default:
            return state
    }
}
