import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// redux
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Spinner from "./components/spinner/Spinner";
import "./components/master/master.scss"
import "./components/master/modal/TechnologyModal.scss"

const App = lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
