import { privateRequest } from "../axiosConfig/privateRequest";
import { getApiParams } from "../../utility/getApiParams";

export const fetchProcessInterview = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest.get(
    `/interviews${params}`
  );
};

export const fetchLeaningOrg = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest.get(
    `/leaving-organisation${params}`
  );
};

export const fetchProcessJoining = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest.get(
    `/joinings${params}`
  );
};

export const fetchProcessNonJoining = (pagination) => {
  const params = getApiParams(pagination)
  return privateRequest.get(
    `/non-joinings${params}`
  );
};

// Post (Create)
export const addProcessInterview = (data) => {
  return privateRequest.post("/interviews", data).then((res) => res?.data?.data);
};

// Put (update)
export const editProcessInterview = (data) => {
  return privateRequest.put(`/interviews/${data.id}`, data).then((res) => res);
};

//EXT_INT start
export const fetchProcessExtInterview = (pagination) => {
  const params = getApiParams(pagination)

  return privateRequest.get(
    `/external-interview${params}`
  );
};

// Post (Create)
export const addProcessExtInterview = (data) => {
  return privateRequest
    .post("/external-interviews", data)
    .then((res) => res?.data?.data);
};

// Put (update)

export const editProcessExtInterview = (data) => {
  return privateRequest
    .put(`/external-interviews/${data.id}`, data)
    .then((res) => res);
};

export const createJoining = (data) => {
  return privateRequest.post("/joinings", data).then((res) => res?.data?.data);
};
export const updateJoining = (data) => {
  return privateRequest.put(`/joinings/${data.id}`, data).then((res) => res);
};

//ALL_INT start
export const fetchProcessAllInterview = () => {
  return privateRequest.get("/allinterviews");
};