import { showErrorNotification, showSuccessNotification } from "../../utility";
import * as actionTypes from "../actionTypes";

const configToast = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const loginActionStart = (userDetails) => {
  return {
    type: actionTypes.LOGIN_START,
    payload: userDetails,
  };
};

export const setToken = (userDetails) => {
  return {
    type: actionTypes.SET_TOKEN,
    payload: userDetails,
  };
};


export const googleLoginActionStart = (googleDetails) => {
  return {
    type: actionTypes.GOOGLE_LOGIN_START,
    payload: googleDetails,
  };
};

export const loginActionSuccess = (user) => {
  showSuccessNotification("Login Successfully!", configToast);
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginActionFaild = (error) => {
  showErrorNotification(error?.data?.message, configToast);
  return {
    type: actionTypes.LOGIN_FAILD,
    payload: error,
  };
};

export const logoutAction = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};
