import {  INTERNAL_INVOICE_HISTORY, INVOICE_HISTORY, INVOICE_MASTER_INVOICE_STATUS } from "../apiEndpoints";
import { privateRequest } from "../axiosConfig/privateRequest";
import { getApiParams } from "../../utility/getApiParams"
export const fetchInvoiceStatus = (params) => {
    const query = getApiParams(params)
    return privateRequest.get(`${INVOICE_MASTER_INVOICE_STATUS}/${query}`).then((res)=>res?.data)
}

export const fetchInvoiceHistory = (params) => {
    const query = getApiParams(params)
    return privateRequest.get(`${INVOICE_HISTORY}${query}`)
}

export const fetchExternalInvoiceStatus = (payload) => {
    return privateRequest.get(`${INVOICE_MASTER_INVOICE_STATUS}${payload}`)
}

export const fetchExternalInvoiceHistory = (payload) => {
    return privateRequest.get(`${INTERNAL_INVOICE_HISTORY}${payload}`)
}
