import { publicRequest } from "../axiosConfig/publicRequest";




export const loginUser = (user) => {
  // console.log('user :>> ', user);
  return publicRequest.post("/auth/login", user).then((res) => res.data.data);
};

export const loginGoogleUser = (user) => {
  return publicRequest.get("/auth/google", user).then((res) => res.data).catch((err)=>console.log('err', err));
};
export const forgotPassword = (email) =>{
  return publicRequest.post("/auth/forgot-password", email).then((res) => res.email);
};