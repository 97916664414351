import * as actionTypes from '../actionTypes';


const storedUser = localStorage.getItem('authUser');

const inititalState = {
    isLogin: false,
    user: storedUser ? JSON.parse(storedUser) : {}, // persist state
    isError: false,
    isAuth: !!storedUser,
    error: {},
    permissions: storedUser ? JSON.parse(storedUser)?.permissions: {}
}

export const loginReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.SET_TOKEN:
            return {
                ...state,
                isAuth: true,
                user: { ...action.payload },
                permissions:{...action.payload.permissions},
            }
        case actionTypes.LOGIN_START:
            return {
                ...state,
                isLogin: true,
                isError: false,
                error: {}
            }

        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                isLogin: false,
                user: { ...action.payload },
                permissions:{...action.payload.permissions},
                isAuth: true,
                isError: false,
                error: {}
            }

        case actionTypes.LOGIN_FAILD:
            return {
                ...state,
                isError: true,
                isLogin: false,
                error: { ...action.payload }
            }

        case actionTypes.LOGOUT:
            localStorage.removeItem('authUser');
            return {
                ...state,
                user: {},
                isAuth: false,
                isError: false,
                permissions:{},
                error: {}
            }
        default:
            return state
    }
}