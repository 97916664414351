import * as actionTypes from '../../actionTypes';

const inititalState = {
    isDataFetch: false,
    TechnologyMaster: [],
    isError: false,
    isAdding: false,
    totalCount: 0,
    isUpdating: false,
    error: {}
}

export const technologyMasterReducer = (state = inititalState, action) => {
    switch (action.type) {
        case actionTypes.TECHNOLOGY_TABLE_DATA_FETCH_START:
            return {
                ...state,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.TECHNOLOGY_DATA_POST_START:
            return {
                ...state,
                isAdding: true,
                isError: false,
                error: {}
            }

        case actionTypes.TECHNOLOGY_DATA_PUT_START:
            return {
                ...state,
                isUpdating: true,
                isDataFetch: true,
                isError: false,
                error: {}
            }

        case actionTypes.TECHNOLOGY_TABLE_DATA_FETCH_SUCCESS:
            return {
                ...state,
                isDataFetch: false,
                TechnologyMaster: [...action.payload.technologies],
                isError: false,
                totalCount: action.payload.count,
                error: {}
            }

        case actionTypes.TECHNOLOGY_DATA_POST_SUCCESS:
            const newTech = [action.payload.technologies, ...state.TechnologyMaster]
            return {
                ...state,
                TechnologyMaster: [...newTech],
                totalCount: action.payload.count,
                isAdding: false,
                error: {},
            }

        case actionTypes.TECHNOLOGY_DATA_PUT_SUCCESS:
            const updateTechnology = [...state.TechnologyMaster]
            const findIndexT = updateTechnology.findIndex(d => d.id === action.payload.id)
            updateTechnology[findIndexT] = { ...action.payload }
            return {
                ...state,
                TechnologyMaster: [...updateTechnology],
                isUpdating: false,
                isDataFetch: false,
                error: {}
            }

        case actionTypes.TECHNOLOGY_TABLE_DATA_FETCH_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                TechnologyMaster: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }

        case actionTypes.TECHNOLOGY_DATA_POST_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                TechnologyMaster: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }

        case actionTypes.TECHNOLOGY_DATA_PUT_FAILED:
            return {
                ...state,
                isError: true,
                isDataFetch: false,
                TechnologyMaster: [],
                isAdding: false,
                isUpdating: false,
                error: { ...action.payload }
            }
        default:
            return state
    }
}
